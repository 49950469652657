import { useAppDispatch } from 'app/hooks'
import { vote } from 'services/surveySlice'
import styled from 'styled-components/macro'
import { getImagePositionDesktopStyles } from 'util/QuestionUtils'

import { useCallback, useEffect, useRef } from 'react'
import {
  DesktopEngagement,
  DesktopEngagements,
  DesktopField,
  DesktopImage,
  DesktopPrompt,
  DesktopTile,
  KeyboardKey,
} from './Shared/SharedDesktopQuestionComponents'
import { Background, Prompt, Submit, Tile } from './Shared/SharedMobileQuestionComponents'

interface Props {
  question: Question
  survey: Survey
  onVote: (question: any, choiceIndex?: any) => void
  orientation?: 'desktop' | 'mobile'
}

export function IntroQuestion({ question, survey, onVote, orientation = 'mobile' }: Props) {
  const dispatch = useAppDispatch()

  const handlingVote = useRef(false)
  const submitButtonRef = useRef(null)

  const handleVote = useCallback(
    (skip?: boolean) => {
      if (handlingVote.current) {
        return
      }
      handlingVote.current = true
      if (!skip) {
        runVoteAnimation()
      }
      setTimeout(
        () => {
          if (onVote) {
            onVote(question)
          }
          handlingVote.current = false
          dispatch(vote(-1))
        },
        skip ? 1 : window.location.href.endsWith('blink') ? 700 : 500
      )
    },
    [survey, question, onVote]
  )

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 13) {
        // enter/return key
        handleVote()
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleVote])

  const runVoteAnimation = () => {
    let button = submitButtonRef.current
    if (button) {
      if (window.location.href.endsWith('blink')) {
        button.style.background = survey.styles?.answerTextColor + '60'
        setTimeout(() => (button.style.background = survey.styles?.answerTextColor + '00'), 100)
        setTimeout(() => (button.style.background = survey.styles?.answerTextColor + '60'), 200)
        setTimeout(() => (button.style.background = survey.styles?.answerTextColor + '00'), 300)
        setTimeout(() => (button.style.background = survey.styles?.answerTextColor + '60'), 400)
        return
      }
      button.style.transform = 'scale(1.4)'
      button.style.opacity = '0'
    }
  }

  if (orientation === 'desktop') {
    return (
      <DesktopTile
        style={Object.assign(
          {
            background: survey.styles?.backgroundImage
              ? `url(${survey.styles.backgroundImage})`
              : survey.styles?.backgroundColor || '#33347d',
          },
          question.styles,
          getImagePositionDesktopStyles(question),
          { justifyContent: 'space-evenly' }
        )}
      >
        <DesktopImage
          src={question.image?.url || 'https://via.placeholder.com/300x400'}
          style={
            question.imagePosition?.startsWith('float')
              ? { height: 'unset', maxHeight: '70%', width: 'unset', maxWidth: '359px' }
              : { margin: 0 }
          }
        />
        <DesktopField
          style={Object.assign(
            {},
            question?.imagePosition?.startsWith('float') && {
              flex: 0.7,
              marginRight: question.imagePosition === 'float-left' ? 'auto' : undefined,
            },
            (question?.imagePosition?.startsWith('full') || question?.imagePosition?.endsWith('right')) && {
              maxWidth: 'max-content',
              margin: '0 auto',
              padding: '0 40px',
            }
          )}
        >
          <DesktopPrompt style={{ color: survey.styles?.questionTextColor || '#fff' }}>
            {question.prompt}
          </DesktopPrompt>
          {question.description && (
            <div style={{ marginTop: 25 }}>
              <DesktopPrompt
                style={{
                  color: survey.styles?.questionTextColor || '#fff',
                  fontSize: 24,
                  fontWeight: 500,
                }}
              >
                {question.description}
              </DesktopPrompt>
            </div>
          )}
          {question.type === 'intro' && (
            <DesktopEngagements>
              <DesktopEngagement
                ref={submitButtonRef}
                color={survey.styles?.answerTextColor}
                onClick={() => handleVote()}
              >
                <KeyboardKey>↵</KeyboardKey>
                {question.choices?.[0]?.label || 'Continue'}
              </DesktopEngagement>
            </DesktopEngagements>
          )}
        </DesktopField>
      </DesktopTile>
    )
  } else {
    return (
      <Tile id={question._id}>
        <OpacityTop>
          <Prompt style={{ color: survey.styles?.questionTextColor || '#fff' }}>{question.prompt}</Prompt>
          {question.description && (
            <Prompt
              style={{
                color: survey.styles?.questionTextColor || '#fff',
                marginTop: 10,
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              {question.description}
            </Prompt>
          )}
        </OpacityTop>
        {question.type === 'intro' && (
          <OpacityBottom>
            <Engagements>
              <Submit onClick={() => handleVote(true)} style={{ textAlign: 'center' }}>
                {question.choices?.[0]?.label || 'Continue'}
              </Submit>
            </Engagements>
          </OpacityBottom>
        )}
        {question.image?.url && <Background src={question.image.url} />}
      </Tile>
    )
  }
}

const OpacityTop = styled.div`
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
`

const OpacityBottom = styled.div`
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
`

const Engagements = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 40px 40px 20px 40px;
`

const Engagement = styled.img`
  height: 70px;
  cursor: pointer;
  user-select: none;
  user-drag: none;
`
