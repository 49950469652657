import { useEffect } from 'react'

export const useKey = (key, func) => {
  const keyHandler = (event) => {
    if (key === ANY_KEY || event.key === key) {
      func(event)
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', keyHandler)

    return () => {
      window.removeEventListener('keydown', keyHandler)
    }
  }, [])
}

export const ANY_KEY = 'any'
