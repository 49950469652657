import { useAppDispatch } from 'app/hooks'
import { useState } from 'react'
import { usePostVoteMutation } from 'services/api'
import { vote } from 'services/surveySlice'
import styled from 'styled-components/macro'
import { getImagePositionDesktopStyles } from 'util/QuestionUtils'
import { DesktopField, DesktopPrompt, DesktopTile } from './Shared/SharedDesktopQuestionComponents'
import { Engagements, Prompt, Submit, Tile } from './Shared/SharedMobileQuestionComponents'

interface Props {
  question: Question
  survey: Survey
  onVote: (question: any, choiceIndex?: any) => void
  orientation?: 'desktop' | 'mobile'
}

export const RankQuestion = ({ question, survey, onVote, orientation = 'mobile' }: Props) => {
  const [rank, setRank] = useState<Choice[]>([])
  const dispatch = useAppDispatch()
  const [postVote] = usePostVoteMutation()

  const handleVote = (submittedRank: any) => {
    if (submittedRank.length < question.choices.length) {
      alert('Please rank all choices')
      return
    }
    if (onVote) {
      onVote(question)
    }
    setRank([])
    if (!survey.previewMode) {
      postVote({
        survey: survey,
        question: question._id,
        choice: submittedRank,
        utm: window.location.search,
      })
    }
    dispatch(vote(submittedRank[0].value))
  }

  const Choice = ({ choice }: { choice: Choice }) => {
    const ranking = rank.indexOf(choice) === -1 ? null : rank.indexOf(choice) + 1
    return (
      <ChoiceContainer
        onClick={() => {
          if (ranking) return setRank(rank.filter((choice) => choice !== choice))
          return setRank([...rank, choice])
        }}
      >
        <ChoiceImageContainer>
          {choice.image?.url && <ChoiceImage src={choice.image.url} />}
          <ChoiceOverlay selected={!!ranking}>{ranking}</ChoiceOverlay>
        </ChoiceImageContainer>
        <ChoiceLabel>{choice.label}</ChoiceLabel>
      </ChoiceContainer>
    )
  }

  if (orientation === 'desktop') {
    return (
      <DesktopTile
        style={Object.assign(
          {
            background: survey.styles?.backgroundImage
              ? `url(${survey.styles.backgroundImage})`
              : survey.styles?.backgroundColor || '#33347d',
          },
          question.styles,
          !!question.image ? getImagePositionDesktopStyles(question) : {},
          { justifyContent: 'space-evenly' }
        )}
      >
        <DesktopField>
          <DesktopPrompt>{question.prompt}</DesktopPrompt>
          <DesktopChoices>
            {question.choices.map((choice: any, index: any) => {
              return <Choice key={index} choice={choice} />
            })}
          </DesktopChoices>
        </DesktopField>
      </DesktopTile>
    )
  } else {
    return (
      <Tile id={question._id}>
        <Prompt style={{ color: survey.styles?.questionTextColor || '#fff' }}>{question.prompt}</Prompt>
        <Choices>
          {question.choices.map((choice: any, index: any) => {
            return <Choice key={index} choice={choice} />
          })}
        </Choices>
        <Engagements>
          <Submit>{'Submit'}</Submit>
        </Engagements>
      </Tile>
    )
  }
}

const DesktopChoices = styled.div`
  zoom: 0.7;
`

const Choices = styled.div`
  padding: 0px;
`

const ChoiceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 16px 0px;
  width: calc(100% - 80px);
  padding: 6px 40px;
  color: #ffffff;
  transition: all 0.25s;

  &:hover {
    background: rgba(255, 255, 255, 0.7);
    color: #08324c;
  }
`

const ChoiceImageContainer = styled.div`
  position: relative;
  height: 72px;
`

const ChoiceImage = styled.img`
  align-self: center;
  height: 68px;
  width: 68px;
  min-width: 68px;
  max-width: 68px;
  border-radius: 40px;
  border: solid 2px #ffffff;
  margin: 0;
  padding: 0;
`

const ChoiceOverlay = styled.div<{ selected: boolean }>`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 36px;
  background: ${({ selected }) => (selected ? 'rgba(255,255,255,0.7)' : 'none')};
  color: #08324c;
  font-size: 30px;
  font-weight: 800;
`

const ChoiceLabel = styled.div`
  font-weight: 500;
  font-size: 18px;
  margin-left: 20px;
`
