import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'

import { Survey } from './components/Survey'

export const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/form/:mask" element={<Survey />} />
      </Routes>
    </Router>
  )
}
