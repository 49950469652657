import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components/macro'

import { centered } from 'styles/layout'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import { useGetSurveyQuery, usePostSurveyInteractionMutation, usePutSurveyMaskMutation } from '../services/api'
import { setCompleted, setQuestionIndex, setStarted, setSurveyData, setViewed } from '../services/surveySlice'

import { CallToAction } from './CallToAction'
import { Question } from './QuestionPreview/Question'

const STORAGE_NAMESPACE = 'bwi.' // local storage namespace

export function Survey() {
  const { mask } = useParams()
  const { data: survey, isLoading } = useGetSurveyQuery(String(mask))
  const questions = useAppSelector((state) => state.survey.questions)
  const endings = useAppSelector((state) => state.survey.endings)
  const questionIndex = useAppSelector((state) => state.survey.questionIndex)
  // const viewed = useAppSelector(state => state.survey.viewed)
  // const started = useAppSelector(state => state.survey.started)
  // const completed = useAppSelector(state => state.survey.completed)
  const { viewed, started, completed } = useAppSelector((state) => state.survey)
  const desktopImageUrl = useAppSelector((state) => state.survey.desktopImageUrl)

  const [postSurveyInteraction] = usePostSurveyInteractionMutation()
  const [putSurveyMask] = usePutSurveyMaskMutation()
  const dispatch = useAppDispatch()

  const [isDesktop, setIsDesktop] = useState<boolean>(window.innerWidth > window.innerHeight)

  useEffect(() => {
    const recheckAspectRatio = () => {
      setIsDesktop(window.innerWidth > window.innerHeight)
    }

    window.addEventListener('resize', recheckAspectRatio)

    return () => {
      window.removeEventListener('resize', recheckAspectRatio)
    }
  })

  // handle views
  useEffect(() => {
    if (!survey || !survey._id) {
      return
    }
    if (!viewed) {
      if (!survey.previewMode) {
        let viewedLocalStorageKey = survey._id + '.view'
        if (!getLocalStorage(viewedLocalStorageKey)) {
          postSurveyInteraction({ type: 'view', survey: survey?._id, utm: window.location.search })
          setLocalStorage(viewedLocalStorageKey, '1')
        }
      }
      dispatch(setViewed())
    }
  }, [survey, viewed, dispatch, postSurveyInteraction])

  // handle starts
  useEffect(() => {
    if (!survey || !survey._id) {
      return
    }
    if (!started && questionIndex > 0) {
      if (!survey.previewMode) {
        let startedLocalStorageKey = survey._id + '.start'
        if (!getLocalStorage(startedLocalStorageKey)) {
          postSurveyInteraction({ type: 'start', survey: survey?._id, utm: window.location.search })
          setLocalStorage(startedLocalStorageKey, '1')
        }
      }
      dispatch(setStarted())
    }
  }, [survey, started, questionIndex, dispatch, postSurveyInteraction])

  // handle completions
  useEffect(() => {
    if (!survey || !survey._id) {
      return
    }
    let completedLocalStorageKey = survey._id + '.completion'
    if (viewed && started && !completed && questionIndex === questions.length) {
      if (!survey.previewMode && !getLocalStorage(completedLocalStorageKey)) {
        postSurveyInteraction({ type: 'completion', survey: survey?._id, utm: window.location.search })
        setLocalStorage(completedLocalStorageKey, '1')
        if (mask && mask.length > 20) {
          putSurveyMask({ _id: mask, delta: { surveyComplete: true } }).catch((err: any) => console.error(err))
        }
      }
      dispatch(setCompleted())
    }
  }, [
    survey,
    viewed,
    started,
    completed,
    questionIndex,
    questions,
    dispatch,
    mask,
    postSurveyInteraction,
    putSurveyMask,
  ])

  // handle existing question votes
  useEffect(() => {
    if (!survey || !survey._id || survey.previewMode) {
      return
    }
    let startingQuestionIndex = 0
    for (let question of questions) {
      if (question._id && !!getLocalStorage(question._id)) {
        startingQuestionIndex++
      } else {
        break
      }
    }
    dispatch(setQuestionIndex(startingQuestionIndex))
  }, [questions, survey, dispatch])

  // handle survey load and existing completion check
  useEffect(() => {
    if (!survey || !survey._id) {
      return
    }

    // load survey
    dispatch(setSurveyData(survey))

    // handle existing completion
    let completedLocalStorageKey = survey._id + '.completion'
    if (!completed && !survey.previewMode && getLocalStorage(completedLocalStorageKey)) {
      dispatch(setCompleted())
    }
  }, [survey, completed, dispatch])

  // LocalStorage getter - prepends STORAGE_NAMESPACE to key
  const getLocalStorage = (key: string) => {
    try {
      return window.localStorage.getItem(STORAGE_NAMESPACE + key)
    } catch (err) {
      console.error(err)
      return null
    }
  }

  // LocalStorage setter - prepends STORAGE_NAMESPACE to key
  const setLocalStorage = (key: string, value: string) => {
    try {
      return window.localStorage.setItem(STORAGE_NAMESPACE + key, value)
    } catch (err) {
      console.error(err)
      return null
    }
  }

  const handleVote = useCallback(
    (question: any, choiceIndex?: number) => {
      if (!survey) return

      if (!survey.previewMode) {
        setLocalStorage(question._id, '' + choiceIndex)
      }

      let earlyExitTriggered = false
      if (
        survey.earlyExits &&
        survey.earlyExits[question._id] &&
        survey.earlyExits[question._id].length &&
        survey.earlyExits[question._id].indexOf(choiceIndex) !== -1
      ) {
        earlyExitTriggered = true
      }

      if (earlyExitTriggered || questionIndex >= survey.questions.length - 1) {
        // survey is complete
        if (!survey.previewMode) {
          // attempt to register question as voted upon in LocalStorage if the server accepted the vote
          let completedLocalStorageKey = survey._id + '.completion'
          if (!getLocalStorage(completedLocalStorageKey)) {
            postSurveyInteraction({ type: 'completion', survey: survey?._id, utm: window.location.search })
            if (mask && mask.length === 24 && /^[a-f0-9]+$/i.test(mask)) {
              // todo: better check for objectid
              putSurveyMask({ _id: mask, delta: { surveyComplete: true } }).catch((err: any) => console.error(err))
            }
            setLocalStorage(completedLocalStorageKey, '1')
          }
        }
        dispatch(setCompleted())
      }
    },
    [survey]
  )

  const question = survey ? survey.questions[questionIndex] : null

  return (
    <SurveyDisplay
      orientation={isDesktop ? 'desktop' : 'mobile'}
      style={
        survey && survey.background
          ? { background: survey.background }
          : {
              background:
                'linear-gradient(45deg, rgb(22, 94, 139), rgb(129, 203, 227)) 50% 50% / cover no-repeat fixed',
            }
      }
    >
      <ProgressBar
        style={
          survey?.styles?.progressBarColor
            ? {
                backgroundColor: survey.styles.progressBarColor.substring(0, 7) + '50',
              }
            : {}
        }
      >
        <ProgressBarFilled
          style={{ backgroundColor: survey?.styles?.progressBarColor }}
          progress={((questionIndex + 1) / (questions.length + Math.sign(endings.length))) * 100}
        />
      </ProgressBar>

      {desktopImageUrl && (
        <LogoWrapper>
          <Logo src={desktopImageUrl} />
        </LogoWrapper>
      )}

      {!completed && question && (
        <ExperienceCanvas>
          <Question
            question={question}
            survey={survey}
            onVote={handleVote}
            orientation={isDesktop ? 'desktop' : 'mobile'}
          />
        </ExperienceCanvas>
      )}

      {completed || (!isLoading && questions.length > 0 && questionIndex >= questions.length) ? (
        endings.length > 0 ? (
          <ExperienceCanvas>
            <Question
              question={endings[0]}
              survey={survey}
              onVote={undefined}
              orientation={isDesktop ? 'desktop' : 'mobile'}
            />
          </ExperienceCanvas>
        ) : (
          <CallToAction survey={survey} />
        )
      ) : null}
    </SurveyDisplay>
  )
}

const ExperienceCanvas = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`

const AspectRatio = styled.div<{ ratio: number }>`
  ${({ ratio }) => (ratio >= 1 ? 'width: 100%' : 'height: 100%')};
  aspect-ratio: ${({ ratio }) => ratio};
`

const ResizeDetector = styled(AspectRatio)`
  ${centered}
  flex-direction: column;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
  overflow: hidden;
`

const ZoomWrapper = styled.div`
  ${centered}
  flex-direction: row;
  transform-origin: center center;
  max-width: 1000px;
  aspect-ratio: inherit;
  background: #3333;
  transition: 200ms transform ease;
`

const SurveyDisplay = styled.div<{ orientation: 'mobile' | 'desktop' }>`
  position: absolute;
  top: ${({ orientation }) => (orientation === 'mobile' ? '50px' : '0')};
  width: 100%;
  height: ${({ orientation }) => (orientation === 'mobile' ? 'calc(100% - 50px)' : '100%')};
  background-size: cover !important;
  overflow: auto;
`

const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #5379b933;
  /* background: #13537b; */
  z-index: 999;
`

interface Progress {
  progress: number
}

const ProgressBarFilled = styled.div<Progress>`
  width: ${(props) => `${props.progress}%`};
  height: 100%;
  background-color: #96a0df;
`

// const ProgressBar = styled.div`
//   width: 100%;
//   height: 3px;
//   background: #13537b;
// `

// const ProgressBarFilled = styled.div<{ progress: number }>`
//   width: ${(props) => `${props.progress}%`};
//   height: 100%;
//   background: #30d158;
// `

const LogoWrapper = styled.div`
  position: relative;
  width: 100%;
  text-align: center;

  @media (max-width: 429px) {
    display: none;
  }
`

const Logo = styled.img`
  width: 280px;
  vertical-align: middle;
`
