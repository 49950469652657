import { useAppDispatch } from 'app/hooks'
import { useEffect, useRef, useState } from 'react'
import { usePostVoteMutation } from 'services/api'
import styled from 'styled-components/macro'
import { getImagePositionDesktopStyles } from 'util/QuestionUtils'
import {
  DesktopEngagement,
  DesktopEngagements,
  DesktopField,
  DesktopImage,
  DesktopPrompt,
  DesktopTile,
  KeyboardKey,
} from './Shared/SharedDesktopQuestionComponents'

import { FoxStopWords, getKeywordsAndScores } from 'lib/rake-keywords'
import { vote } from 'services/surveySlice'
import { useKey } from 'util/useKey'
import {
  Background,
  Engagements,
  OpacityBottom,
  OpacityTop,
  Prompt,
  Submit,
  Tile,
} from './Shared/SharedMobileQuestionComponents'

interface Props {
  question: Question
  survey: Survey
  onVote: (question: any, choiceIndex?: any) => void
  orientation?: 'desktop' | 'mobile'
}

export const TextQuestion = ({ question, survey, onVote, orientation = 'mobile' }: Props) => {
  const dispatch = useAppDispatch()
  const [postVote] = usePostVoteMutation()

  const [response, setResponse] = useState<string>()
  const responseRef = useRef(response)

  const handlingVote = useRef(false)
  const submitButtonRef = useRef(null)
  const skipButtonRef = useRef(null)

  const handleVote = (skip?: boolean) => {
    if (handlingVote.current) {
      return
    }
    handlingVote.current = true
    runVoteAnimation(skip)
    setTimeout(
      () => {
        var keywords: any
        if (responseRef.current) {
          var keywordsAndScores = getKeywordsAndScores(responseRef.current.toLowerCase(), FoxStopWords)
          if (keywordsAndScores.length > 10) {
            keywordsAndScores = keywordsAndScores.slice(0, 5)
          }
          keywords = keywordsAndScores.map((keywordAndScore) => keywordAndScore.keyword)
        }
        if (onVote) {
          onVote(question)
        }
        if (!survey.previewMode) {
          postVote({
            survey: survey,
            question: question._id,
            choice: [{ value: -1, label: skip ? '' : responseRef.current }],
            responseKeywords: keywords,
            utm: window.location.search,
          })
        }
        handlingVote.current = false
        dispatch(vote(-1))
      },
      window.location.href.endsWith('blink') ? 700 : 500
    )
  }

  useKey('Enter', (event: KeyboardEvent) => {
    if (event.shiftKey) {
      event.preventDefault()
      handleVote()
    }
  })

  useEffect(() => {
    responseRef.current = response
  }, [response])

  const runVoteAnimation = (skipping?: boolean) => {
    let button = skipping ? skipButtonRef.current : submitButtonRef.current
    if (button) {
      if (window.location.href.endsWith('blink')) {
        button.style.background = survey.styles?.answerTextColor + '60'
        setTimeout(() => (button.style.background = survey.styles?.answerTextColor + '00'), 100)
        setTimeout(() => (button.style.background = survey.styles?.answerTextColor + '60'), 200)
        setTimeout(() => (button.style.background = survey.styles?.answerTextColor + '00'), 300)
        setTimeout(() => (button.style.background = survey.styles?.answerTextColor + '60'), 400)
        return
      }
      button.style.transform = 'scale(1.4)'
      button.style.opacity = '0'
    }
  }

  if (orientation === 'desktop') {
    return (
      <DesktopTile
        key={'d' + question._id}
        style={Object.assign(
          {
            background: survey.styles?.backgroundImage
              ? `url(${survey.styles.backgroundImage})`
              : survey.styles?.backgroundColor || '#33347d',
          },
          question.styles,
          getImagePositionDesktopStyles(question),
          { justifyContent: 'space-evenly' }
        )}
      >
        <DesktopImage
          style={
            question.imagePosition?.startsWith('float')
              ? { height: 'unset', maxHeight: '70%', width: 'unset', maxWidth: '359px' }
              : { margin: 0 }
          }
          src={question.image?.url}
        />
        <DesktopField
          style={Object.assign(
            {
              maxWidth: 600,
            },
            question?.imagePosition?.startsWith('float') && {
              flex: 0.7,
              marginRight: question.imagePosition === 'float-left' ? 'auto' : undefined,
            },
            (question?.imagePosition?.startsWith('full') || question?.imagePosition?.endsWith('right')) && {
              margin: '0 auto',
              padding: '0 40px',
            }
          )}
        >
          <DesktopPrompt style={{ color: survey.styles?.questionTextColor || '#fff' }}>
            {question.prompt}
          </DesktopPrompt>
          <span style={{ position: 'relative' }}>
            <DesktopTextArea
              maxLength={
                question?.characterLimitActive && !isNaN(question?.characterLimit)
                  ? question?.characterLimit
                  : undefined
              }
              placeholder={'Type your thoughts here...'}
              value={response}
              onChange={(e) => setResponse(e.target.value)}
            />
            {!!question?.characterLimitActive && (
              <p style={{ position: 'absolute', bottom: 20, right: 20, color: '#777', fontSize: 16 }}>
                <span style={{ fontSize: 20 }}>{response?.length || 0}</span>
                <span>/{question?.characterLimit}</span>
              </p>
            )}
          </span>
          <DesktopEngagements>
            <DesktopEngagement key={'dsu' + question._id} ref={submitButtonRef} onClick={() => handleVote()}>
              <KeyboardKey style={{ width: 'auto', marginRight: 0, padding: '0 3px 1px' }}>shift</KeyboardKey>
              <span style={{ marginRight: 4, marginLeft: 4, fontSize: 12 }}>+</span>
              <KeyboardKey>↵</KeyboardKey>
              Submit
            </DesktopEngagement>
            <DesktopEngagement key={'dsk' + question._id} ref={skipButtonRef} onClick={() => handleVote(true)}>
              Skip
            </DesktopEngagement>
          </DesktopEngagements>
        </DesktopField>
      </DesktopTile>
    )
  } else {
    return (
      <Tile id={question._id} key={'m' + question._id}>
        <OpacityTop>
          <Prompt style={{ color: survey.styles?.questionTextColor || '#fff' }}>{question.prompt}</Prompt>
        </OpacityTop>
        <span style={{ position: 'relative' }}>
          <TextInput
            maxLength={
              question?.characterLimitActive && !isNaN(question?.characterLimit)
                ? question?.characterLimit
                : undefined
            }
            value={response}
            placeholder={'Type your thoughts here...'}
            onChange={(e) => setResponse(e.target.value)}
          />
          {!!question?.characterLimitActive && (
            <p style={{ position: 'absolute', bottom: 20, right: 40, color: '#777', fontSize: 16 }}>
              <span style={{ fontSize: 20 }}>{response?.length || 0}</span>
              <span>/{question?.characterLimit}</span>
            </p>
          )}
        </span>
        <OpacityBottom>
          <Engagements style={{ marginRight: '20px', marginLeft: '20px' }}>
            <Submit onClick={() => handleVote()} style={{ textAlign: 'center' }}>
              Skip
            </Submit>
            <Submit onClick={() => handleVote()} style={{ textAlign: 'center' }}>
              Submit
            </Submit>
          </Engagements>
        </OpacityBottom>
        {question.image?.url && <Background src={question.image.url} />}
      </Tile>
    )
  }
}

const DesktopTextArea = styled.textarea`
  box-sizing: border-box;
  margin-top: 30px;
  width: 100%;
  padding: 20px;
  font-family: 'Avenir';
  font-size: 20px;
  height: 240px;
  font-weight: 500;
  color: #33347d;
  background: rgba(255, 255, 255, 0.9);
  // background: rgba(0,0,0,0.8);
  outline: none;
  border: none;
  resize: none;
`

const TextInput = styled.textarea`
  width: calc(100% - 80px);
  margin-left: 20px;
  padding: 20px;
  font-family: 'Avenir';
  font-size: 20px;
  height: 240px;
  font-weight: 500;
  color: #333333;
  background: rgba(255, 255, 255, 0.8);
  outline: none;
  border: none;
  resize: none;
`
