import styled from 'styled-components/macro'

export const DesktopTile = styled.div<{ styles?: any }>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;

  flex-direction: ${({ styles }) => styles?.layout || 'row'};
  color: ${({ styles }) => styles?.color || '#ffffff'};
  background: ${({ styles }) => styles?.background || '#33347d'};
`

export const DesktopImage = styled.img`
  /* height: 100%;
  width: 359px;
  background: rgba(255, 255, 255, 0.3); */
  display: block;
  margin: 0px auto;
  width: 50%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
`

export const DesktopField = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 50px;
`

export const DesktopPrompt = styled.p`
  font-size: 30px;
  font-weight: 600;
  &::placeholder {
    color: inherit;
  }
`

export const DesktopEngagements = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 30px;
`

export const DesktopEngagement = styled.div<{ color?: string }>`
  font-size: 16px;
  padding: 10px 20px;
  color: ${({ color }) => color || '#ffffff'};
  border: 1px solid ${({ color }) => color || '#ffffff'};
  border-radius: 10px;
  cursor: pointer;
  transition: 0.2s all;
  font-weight: 600;
  margin: 10px;
  width: max-content;

  &:hover {
    background: ${({ color }) => (color || '#ffffff') + '40'}; // 0x40 is 0.25 alpha (rgba)
  }

  // keybinds WIP
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
`

export const KeyboardKey = styled.kbd`
  align-items: center;
  background: linear-gradient(-225deg, #d5dbe4, #f8f8f8);
  border: 0;
  border-radius: 2px;
  background: linear-gradient(-225deg, #181950, #33347d);
  box-shadow: inset 0 -2px 0 0 #23236d, inset 0 0 1px 1px #33347d, 0 1px 2px 1px rgba(30, 35, 90, 0.4);
  color: #b8c3d5;
  display: flex;
  height: 18px;
  justify-content: center;
  margin-right: 12px;
  padding: 0 0 1px;
  width: 20px;
  font-size: 13px;
`
