import { useAppDispatch } from 'app/hooks'
import { useCallback, useRef } from 'react'
import { usePostVoteMutation } from 'services/api'
import { vote } from 'services/surveySlice'
import styled from 'styled-components/macro'
import { getImagePositionDesktopStyles } from 'util/QuestionUtils'

import { useKey } from 'util/useKey'
import {
  DesktopEngagement,
  DesktopEngagements,
  DesktopField,
  DesktopImage,
  DesktopPrompt,
  DesktopTile,
  KeyboardKey,
} from './Shared/SharedDesktopQuestionComponents'
import { Background, Prompt, Tile } from './Shared/SharedMobileQuestionComponents'

interface Props {
  question: Question
  survey: Survey
  onVote: (question: any, choiceIndex?: any) => void
  orientation?: 'desktop' | 'mobile'
}

export const BooleanQuestion = ({ question, survey, onVote, orientation = 'mobile' }: Props) => {
  const dispatch = useAppDispatch()
  const [postVote] = usePostVoteMutation()

  const handlingVote = useRef(false)
  const voteButtonRefs = [
    useRef(null), // no
    useRef(null), // yes
    useRef(null), // not sure
  ]

  const handleVote = useCallback(
    (voteValue: number) => {
      if (handlingVote.current) {
        return
      }
      handlingVote.current = true
      runVoteAnimation(voteValue)
      setTimeout(
        () => {
          if (onVote) {
            onVote(question, voteValue)
          }
          if (!survey.previewMode) {
            postVote({
              survey: survey,
              question: question._id,
              choice: [question.choices[voteValue]],
              utm: window.location.search,
            }) // TODO: question.choices[voteValue].value
          }
          handlingVote.current = false
          dispatch(vote(voteValue))
        },
        window.location.href.endsWith('blink') ? 700 : 500
      )
    },
    [survey, question, onVote]
  )
  useKey('y', () => handleVote(1))
  useKey('Y', () => handleVote(1))
  useKey('n', () => handleVote(0))
  useKey('N', () => handleVote(0))
  useKey('?', () => handleVote(2))

  const runVoteAnimation = (voteValue) => {
    let button = voteButtonRefs[voteValue].current
    if (button) {
      if (window.location.href.endsWith('blink')) {
        button.style.background = survey.styles?.answerTextColor + '60'
        setTimeout(() => (button.style.background = survey.styles?.answerTextColor + '00'), 100)
        setTimeout(() => (button.style.background = survey.styles?.answerTextColor + '60'), 200)
        setTimeout(() => (button.style.background = survey.styles?.answerTextColor + '00'), 300)
        setTimeout(() => (button.style.background = survey.styles?.answerTextColor + '60'), 400)
        return
      }
      button.style.transform = 'scale(1.4)'
      button.style.opacity = '0'
    }
  }

  if (orientation === 'desktop') {
    return (
      <DesktopTile
        key={'d' + question._id}
        style={Object.assign(
          {
            background: survey.styles?.backgroundImage
              ? `url(${survey.styles.backgroundImage})`
              : survey.styles?.backgroundColor || '#33347d',
          },
          question.styles,
          getImagePositionDesktopStyles(question),
          { justifyContent: 'space-evenly' }
        )}
      >
        <DesktopImage
          src={question.image?.url || 'https://via.placeholder.com/300x400'}
          style={
            question.imagePosition?.startsWith('float')
              ? { height: 'unset', maxHeight: '70%', width: 'unset', maxWidth: '359px' }
              : { margin: 0 }
          }
        />
        <DesktopField
          style={Object.assign(
            {},
            question?.imagePosition?.startsWith('float') && {
              flex: 0.7,
              marginRight: question.imagePosition === 'float-left' ? 'auto' : undefined,
            },
            (question?.imagePosition?.startsWith('full') || question?.imagePosition?.endsWith('right')) && {
              maxWidth: 'max-content',
              margin: '0 auto',
              padding: '0 40px',
            }
          )}
        >
          <DesktopPrompt style={{ color: survey.styles?.questionTextColor || '#fff' }}>
            {question.prompt}
          </DesktopPrompt>
          <DesktopEngagements>
            <DesktopEngagement
              ref={voteButtonRefs[1]}
              color={survey.styles?.answerTextColor}
              onClick={() => handleVote(1)}
            >
              <KeyboardKey>Y</KeyboardKey>
              Yes
            </DesktopEngagement>
            <DesktopEngagement
              ref={voteButtonRefs[0]}
              color={survey.styles?.answerTextColor}
              onClick={() => handleVote(0)}
            >
              <KeyboardKey>N</KeyboardKey>
              No
            </DesktopEngagement>
            <DesktopEngagement
              ref={voteButtonRefs[2]}
              color={survey.styles?.answerTextColor}
              onClick={() => handleVote(2)}
            >
              <KeyboardKey>?</KeyboardKey>
              Not sure
            </DesktopEngagement>
          </DesktopEngagements>
        </DesktopField>
      </DesktopTile>
    )
  } else {
    return (
      <Tile id={question._id} key={'m' + question._id}>
        <OpacityTop>
          <Prompt style={{ color: survey.styles?.questionTextColor || '#fff' }}>{question.prompt}</Prompt>
        </OpacityTop>
        <OpacityBottom>
          <Engagements>
            <Engagement onClick={() => handleVote(0)} src={'/img/engagement_icons/no.png'} />
            <Engagement onClick={() => handleVote(1)} src={'/img/engagement_icons/not-sure.png'} />
            <Engagement onClick={() => handleVote(2)} src={'/img/engagement_icons/yes.png'} />
          </Engagements>
        </OpacityBottom>
        {question.image?.url && <Background src={question.image.url} />}
      </Tile>
    )
  }
}

const OpacityTop = styled.div`
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
`

const OpacityBottom = styled.div`
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
`

const Engagements = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 40px 40px 20px 40px;
`

const Engagement = styled.img`
  height: 70px;
  cursor: pointer;
  user-select: none;
  user-drag: none;
`
