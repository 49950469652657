import { useAppDispatch } from 'app/hooks'
import { useEffect, useRef, useState } from 'react'
import { usePostVoteMutation } from 'services/api'
import { vote } from 'services/surveySlice'
import styled from 'styled-components/macro'
import { getImagePositionDesktopStyles } from 'util/QuestionUtils'
import { ANY_KEY, useKey } from 'util/useKey'
import { DesktopField, DesktopPrompt, KeyboardKey } from './Shared/SharedDesktopQuestionComponents'
import { Tile } from './Shared/SharedMobileQuestionComponents'

interface Props {
  question: Question
  survey: Survey
  onVote: (question: any, choiceIndex?: any) => void
  orientation?: 'desktop' | 'mobile'
}

export const ImageRankQuestion = ({ question, survey, onVote, orientation = 'mobile' }: Props) => {
  const [rank, setRank] = useState<Choice[]>([])
  const rankRef = useRef(rank)
  const isQuad = question.choices.length === 4

  const dispatch = useAppDispatch()
  const [postVote] = usePostVoteMutation()

  const handleVote = (submittedRank: any) => {
    if (submittedRank.length < question.choices.length) {
      alert('Please rank all choices')
      return
    }
    if (onVote) {
      onVote(question)
    }
    setRank([])
    if (!survey.previewMode) {
      postVote({
        survey: survey,
        question: question._id,
        choice: submittedRank,
        utm: window.location.search,
      })
    }
    dispatch(vote(submittedRank[0].value))
  }

  useEffect(() => {
    rankRef.current = rank
  }, [rank])

  const rankChoiceToggle = (choiceIndex: number) => {
    const choice = question.choices[choiceIndex]
    const ranking = rankRef.current.indexOf(choice) === -1 ? null : rankRef.current.indexOf(choice) + 1
    if (ranking) {
      return setRank(rankRef.current.filter((eachChoice) => eachChoice !== choice))
    }
    if (rankRef.current.length === question.choices.length - 1) {
      handleVote([...rankRef.current, choice])
    }
    return setRank([...rankRef.current, choice])
  }

  useKey(ANY_KEY, (event) => {
    for (let choiceIndex = 0; choiceIndex < question.choices.length; choiceIndex++) {
      if (event.key === `${choiceIndex + 1}`) {
        rankChoiceToggle(choiceIndex)
      }
    }
  })

  if (orientation === 'desktop') {
    return (
      <DesktopTile
        style={Object.assign(
          {
            background: survey.styles?.backgroundImage
              ? `url(${survey.styles.backgroundImage})`
              : survey.styles?.backgroundColor || '#33347d',
          },
          question.styles,
          !!question.image ? getImagePositionDesktopStyles(question) : {},
          { justifyContent: 'space-evenly' }
        )}
      >
        <DesktopField>
          <DesktopPrompt style={{ color: survey.styles?.questionTextColor || '#fff' }}>
            {question.prompt}
          </DesktopPrompt>
        </DesktopField>
        <DesktopChoices>
          {question.choices.map((choice, index) => (
            <DesktopChoiceWrapper
              key={index}
              rank={rank.map((choice) => choice.value).indexOf(choice.value) + 1}
              onClick={() => {
                const ranking = rank.indexOf(choice) === -1 ? null : rank.indexOf(choice) + 1
                if (ranking) return setRank(rank.filter((eachChoice) => eachChoice !== choice))
                if (rank.length === question.choices.length - 1) {
                  handleVote([...rank, choice])
                }
                return setRank([...rank, choice])
              }}
            >
              <DesktopChoiceLabel>
                <KeyboardKey
                  style={{
                    background: 'linear-gradient(-225deg, #000000, #33347d)',
                    boxShadow:
                      'inset 0 -2px 0 0 #121216, inset 0 0 1px 1px #33347d, 0 1px 2px 1px rgba(30, 35, 90, 0.4)',
                  }}
                >
                  {choice.value + 1}
                </KeyboardKey>
                {choice.label}
              </DesktopChoiceLabel>
              <DesktopChoice
                style={
                  choice.image?.url
                    ? {
                        backgroundImage: `url(${choice.image.url})`,
                      }
                    : undefined
                }
              />
              {rank.map((choice) => choice.value).indexOf(choice.value) + 1 > 0 && (
                <DesktopChoiceRank>
                  <RankWrapper>{rank.map((choice) => choice.value).indexOf(choice.value) + 1}</RankWrapper>
                </DesktopChoiceRank>
              )}
            </DesktopChoiceWrapper>
          ))}
        </DesktopChoices>
      </DesktopTile>
    )
  } else {
    return (
      <Tile id={question._id}>
        <Choices style={{ flexDirection: isQuad ? 'row' : 'column' }}>
          {question.choices.map((choice: any, index: number) => (
            <ChoiceContainer
              key={index}
              isQuad={isQuad}
              // rank={rank.map((choice) => choice.value).indexOf(choice.value) + 1}
              onClick={() => {
                const ranking = rank.indexOf(choice) === -1 ? null : rank.indexOf(choice) + 1
                if (ranking) return setRank(rank.filter((eachChoice) => eachChoice !== choice))
                if (rank.length === question.choices.length - 1) {
                  handleVote([...rank, choice])
                }
                return setRank([...rank, choice])
              }}
            >
              <ChoiceLabel position={(isQuad && index < 2) || (!isQuad && index < 1) ? 'top' : 'bottom'}>
                {choice.label}
              </ChoiceLabel>
              <ChoiceImage src={choice?.image?.url} />
              {rank.map((choice) => choice.value).indexOf(choice.value) + 1 > 0 && (
                <MobileChoiceRank>
                  <RankWrapper>{rank.map((choice) => choice.value).indexOf(choice.value) + 1}</RankWrapper>
                </MobileChoiceRank>
              )}
            </ChoiceContainer>
          ))}
        </Choices>
        {question.prompt && (
          <PromptWrapper>
            <Prompt style={{ color: survey.styles?.questionTextColor || '#fff' }}>{question.prompt}</Prompt>
          </PromptWrapper>
        )}
      </Tile>
    )
  }
}

const DesktopTile = styled.div`
  box-sizing: border-box;
  padding-top: 50px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background: #33347d;
  color: #ffffff;
`

const DesktopChoices = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex: 3;
`

const DesktopChoiceWrapper = styled.div<{ rank: number }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  padding-top: 10px;
  margin-bottom: -10px;
  color: #ffffff;
  width: 25%;
  bottom: 0;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  ${({ rank }) =>
    rank > 0 &&
    `transform: translateY(-10px);
      background: #ffffff;
      color: #33347d;`}

  &:hover {
    transform: translateY(-10px);
    background: #ffffff;
    color: #33347d;
  }
`

const DesktopChoice = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  overflow: hidden; // prevents blur bleed
`

const DesktopChoiceLabel = styled.p`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 14px 10px;
  box-sizing: border-box;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
`

const DesktopChoiceRank = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 40px);
  margin-top: 30px;
  background: rgba(255, 255, 255, 0.5);
`

const MobileChoiceRank = styled.div`
  top: 0;
  left: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
`

const RankWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  font-size: 20px;
  color: #ffffff;
  background: #33347d;
  border-radius: 25px;
`

const PromptWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
  pointer-events: none;
`

const Prompt = styled.div`
  padding: 60px 30px;
  width: calc(100% - 60px);
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0));
`

const Choices = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const ChoiceContainer = styled.div<{ isQuad: boolean; rank?: number }>`
  position: relative;
  width: ${({ isQuad }) => (isQuad ? '50%' : '100%')};
  margin-bottom: -4px;
  aspect-ratio: ${({ isQuad }) => (isQuad ? '0.639' : '1.279')};
  background: #33347d;
  color: #ffffff;
  cursor: pointer;
`

const ChoiceLabel = styled.div<{ position: 'top' | 'bottom' }>`
  width: 100%;
  font-weight: 600;
  /* backdrop-filter: blur(3px); */
  font-size: 16px;
  box-sizing: border-box;
  text-align: center;
  padding: 10px 0px;

  position: absolute;
  ${({ position }) => (position === 'top' ? `top: 0;` : `bottom: 0;`)}
  ${({ position }) =>
    position === 'top'
      ? `background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0))`
      : `background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9))`}
`

const ChoiceImage = styled.img`
  height: 100%;
  width: 100%;
`
