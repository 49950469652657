import { useAppDispatch } from 'app/hooks'
import { useCallback, useEffect, useRef, useState } from 'react'
import { usePostVoteMutation } from 'services/api'
import { vote } from 'services/surveySlice'
import styled from 'styled-components/macro'
import { getImagePositionDesktopStyles } from 'util/QuestionUtils'
import { useKey } from 'util/useKey'
import {
  DesktopEngagement,
  DesktopField,
  DesktopImage,
  DesktopPrompt,
  DesktopTile,
  KeyboardKey,
} from './Shared/SharedDesktopQuestionComponents'
import {
  Background,
  OpacityBottom,
  OpacityTop,
  Prompt,
  Submit,
  Tile,
} from './Shared/SharedMobileQuestionComponents'

interface Props {
  question: Question
  survey: Survey
  onVote: (question: any, choiceIndex?: any) => void
  orientation?: 'desktop' | 'mobile'
}

export function RateQuestion({ question, survey, orientation = 'mobile', onVote }: Props) {
  const dispatch = useAppDispatch()
  const [postVote] = usePostVoteMutation()

  const [slider, setSlider] = useState<number>(50)
  const sliderRef = useRef<number>(slider)

  const submitButtonRef = useRef(null)
  const handlingVote = useRef(false)

  useKey('1', () => setSlider(0))
  useKey('2', () => setSlider(25))
  useKey('3', () => setSlider(50))
  useKey('4', () => setSlider(75))
  useKey('5', () => setSlider(100))

  const handleVote = useCallback(() => {
    if (handlingVote.current) {
      return
    }
    handlingVote.current = true
    runVoteAnimation()
    setTimeout(
      () => {
        if (onVote) {
          onVote(question)
        }
        let quantizedRating = Math.floor(sliderRef.current / 20) // score out of 100 -> score out of 5
        if (!survey.previewMode) {
          postVote({
            survey: survey,
            question: question._id,
            choice: [{ value: quantizedRating, label: '' }],
            utm: window.location.search,
          })
        }
        handlingVote.current = false
        dispatch(vote(quantizedRating))
      },
      window.location.href.endsWith('blink') ? 700 : 500
    )
  }, [survey, question, onVote])

  useKey('Enter', handleVote)

  useEffect(() => {
    sliderRef.current = slider
  }, [slider])

  const runVoteAnimation = () => {
    let button = submitButtonRef.current
    if (button) {
      if (window.location.href.endsWith('blink')) {
        button.style.background = survey.styles?.answerTextColor + '60'
        setTimeout(() => (button.style.background = survey.styles?.answerTextColor + '00'), 100)
        setTimeout(() => (button.style.background = survey.styles?.answerTextColor + '60'), 200)
        setTimeout(() => (button.style.background = survey.styles?.answerTextColor + '00'), 300)
        setTimeout(() => (button.style.background = survey.styles?.answerTextColor + '60'), 400)
        return
      }
      button.style.transform = 'scale(1.4)'
      button.style.opacity = '0'
    }
  }

  if (orientation === 'desktop') {
    return (
      <DesktopTile
        key={'d' + question._id}
        style={Object.assign(
          {
            background: survey.styles?.backgroundImage
              ? `url(${survey.styles.backgroundImage})`
              : survey.styles?.backgroundColor || '#33347d',
          },
          question.styles,
          getImagePositionDesktopStyles(question),
          { justifyContent: 'space-evenly' }
        )}
      >
        <DesktopImage
          src={question.image?.url || 'https://via.placeholder.com/300x400'}
          style={
            question.imagePosition?.startsWith('float')
              ? { height: 'unset', maxHeight: '70%', width: 'unset', maxWidth: '359px' }
              : { margin: 0 }
          }
        />
        <DesktopField
          style={Object.assign(
            {},
            question?.imagePosition?.startsWith('float') && {
              flex: 0.7,
              marginRight: question.imagePosition === 'float-left' ? 'auto' : undefined,
            },
            (question?.imagePosition?.startsWith('full') || question?.imagePosition?.endsWith('right')) && {
              maxWidth: 'max-content',
              margin: '0 auto',
              padding: '0 40px',
            }
          )}
        >
          <DesktopPrompt style={{ color: survey.styles?.questionTextColor || '#fff' }}>
            {question.prompt}
          </DesktopPrompt>
          <DesktopEngagements>
            <Slider
              style={{ minWidth: 400 }}
              type="range"
              min={0}
              max={100}
              value={slider}
              onChange={(e) => setSlider(parseInt(e.target.value))}
            />
            <RateLabels>
              <p style={{ textAlign: 'left', width: '35%%', marginRight: '15%' }}>
                {question.choices[0]?.label || ''}
              </p>
              <p style={{ textAlign: 'right', width: '35%%', marginLeft: '15%' }}>
                {question.choices[1]?.label || ''}
              </p>
            </RateLabels>
            <DesktopEngagement ref={submitButtonRef} onClick={() => handleVote()}>
              <KeyboardKey>↵</KeyboardKey>
              Submit
            </DesktopEngagement>
          </DesktopEngagements>
        </DesktopField>
      </DesktopTile>
    )
  } else {
    return (
      <Tile id={question._id} key={'m' + question._id}>
        <OpacityTop>
          <Prompt style={{ color: survey.styles?.questionTextColor || '#fff' }}>{question.prompt}</Prompt>
        </OpacityTop>
        <OpacityBottom>
          <Engagements>
            <Slider />
            <RateLabels>
              <p style={{ textAlign: 'left', width: '35%%', marginRight: '15%' }}>
                {question.choices[0]?.label || ''}
              </p>
              <p style={{ textAlign: 'right', width: '35%%', marginLeft: '15%' }}>
                {question.choices[1]?.label || ''}
              </p>
            </RateLabels>
            <Submit onClick={() => handleVote()}>{'Submit'}</Submit>
          </Engagements>
        </OpacityBottom>
        {question.image?.url && <Background src={question.image.url} />}
      </Tile>
    )
  }
}

const Engagements = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0 40px 0;
`
const Slider = styled.input.attrs({ type: 'range' })`
  background-color: blue;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  height: 25px;
  background: #d3d3d3;
  outline: none;
  // opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;

  height: 12px;
  border-radius: 20px;
  width: 100%;
  margin-bottom: 20px;
  background: linear-gradient(
    90deg,
    rgb(0, 0, 255) 1%,
    rgb(226, 204, 223) 45%,
    rgb(229, 183, 199) 55%,
    rgb(255, 0, 0) 100%
  );
  z-index: 2;

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 35px;
    height: 35px;
    border-radius: 35px;
    outline: none;
    background: white;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 35px;
    height: 35px;
    border-radius: 35px;
    outline: none;
    background: white;
    cursor: pointer;
  }
`

const RateLabels = styled.div`
  width: 100%;
  font-weight: 900;
  font-size: 14px;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  margin-bottom: 40px;
`

const DesktopEngagements = styled.div`
  display: block;
  margin-top: 30px;
`
