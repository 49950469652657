import { useAppDispatch } from 'app/hooks'
import { usePostVoteMutation } from 'services/api'
import { vote } from 'services/surveySlice'
import styled from 'styled-components/macro'
import { getImagePositionDesktopStyles } from 'util/QuestionUtils'
import { ANY_KEY, useKey } from 'util/useKey'
import { DesktopField, DesktopPrompt, KeyboardKey } from './Shared/SharedDesktopQuestionComponents'
import { Tile } from './Shared/SharedMobileQuestionComponents'

interface Props {
  question: Question
  survey: Survey
  onVote: (question: any, choiceIndex?: any) => void
  orientation?: 'desktop' | 'mobile'
}

export const ImageChoiceQuestion = ({ question, survey, onVote, orientation = 'mobile' }: Props) => {
  const dispatch = useAppDispatch()
  const [postVote] = usePostVoteMutation()

  const handleVote = (choiceValue: any) => {
    if (onVote) {
      onVote(question, choiceValue)
    }
    if (!survey.previewMode) {
      postVote({
        survey: survey,
        question: question._id,
        choice: question.choices[choiceValue] as unknown as any,
        utm: window.location.search,
      })
    }
    dispatch(vote(choiceValue))
  }

  const isQuad = question.choices.length === 4

  useKey(ANY_KEY, (event) => {
    for (let choiceIndex = 0; choiceIndex < question.choices.length; choiceIndex++) {
      if (event.key === `${choiceIndex + 1}`) {
        handleVote(choiceIndex)
      }
    }
  })

  if (orientation === 'desktop') {
    return (
      <DesktopTile
        style={Object.assign(
          {
            background: survey.styles?.backgroundImage
              ? `url(${survey.styles.backgroundImage})`
              : survey.styles?.backgroundColor || '#33347d',
          },
          question.styles,
          !!question.image ? getImagePositionDesktopStyles(question) : {},
          { justifyContent: 'space-evenly' }
        )}
      >
        <DesktopField>
          <DesktopPrompt style={{ color: survey.styles?.questionTextColor || '#fff' }}>
            {question.prompt}
          </DesktopPrompt>
        </DesktopField>
        <DesktopChoices>
          {question.choices.map((choice, index) => (
            // <DesktopChoiceWrapper key={index}>
            //   {choice.label}
            //   <DesktopChoice src={choice?.image?.url} onClick={() => handleVote(choice.value)} />
            // </DesktopChoiceWrapper>

            <DesktopChoiceWrapper key={index} onClick={() => handleVote(choice.value)}>
              <DesktopChoiceLabel>
                <KeyboardKey
                  style={{
                    background: 'linear-gradient(-225deg, #000000, #33347d)',
                    boxShadow:
                      'inset 0 -2px 0 0 #121216, inset 0 0 1px 1px #33347d, 0 1px 2px 1px rgba(30, 35, 90, 0.4)',
                  }}
                >
                  {choice.value + 1}
                </KeyboardKey>
                {choice.label}
              </DesktopChoiceLabel>
              <DesktopChoice
                style={
                  choice.image?.url
                    ? {
                        backgroundImage: `url(${choice.image.url})`,
                      }
                    : undefined
                }
              />
            </DesktopChoiceWrapper>
          ))}
        </DesktopChoices>
      </DesktopTile>
    )
  } else {
    return (
      <Tile id={question._id}>
        <Choices style={{ flexDirection: isQuad ? 'row' : 'column' }}>
          {question.choices.map((choice: any, index: number) => (
            <ChoiceContainer key={index} isQuad={isQuad} onClick={() => handleVote(choice.value)}>
              <ChoiceLabel position={(isQuad && index < 2) || (!isQuad && index < 1) ? 'top' : 'bottom'}>
                {choice.label}
              </ChoiceLabel>
              <ChoiceImage src={choice?.image?.url} />
            </ChoiceContainer>
          ))}
        </Choices>
        {question.prompt && (
          <PromptWrapper>
            <Prompt style={{ color: survey.styles?.questionTextColor || '#fff' }}>{question.prompt}</Prompt>
          </PromptWrapper>
        )}
      </Tile>
    )
  }
}

const DesktopTile = styled.div`
  box-sizing: border-box;
  padding-top: 50px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background: #33347d;
  color: #ffffff;
`

const DesktopChoices = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex: 3;
`

const DesktopChoiceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  padding-top: 10px;
  margin-bottom: -10px;
  color: #ffffff;
  width: 25%;
  bottom: 0;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: translateY(-10px);
    background: #ffffff;
    color: #33347d;
  }
`

const DesktopChoice = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  overflow: hidden; // prevents blur bleed
`

const DesktopChoiceLabel = styled.p`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 14px 10px;
  box-sizing: border-box;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
`

const PromptWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
  pointer-events: none;
`

const Prompt = styled.div`
  padding: 60px 30px;
  width: calc(100% - 60px);
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0));
`

const Choices = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const ChoiceContainer = styled.div<{ isQuad: boolean }>`
  position: relative;
  width: ${({ isQuad }) => (isQuad ? '50%' : '100%')};
  margin-bottom: -4px;
  aspect-ratio: ${({ isQuad }) => (isQuad ? '0.639' : '1.279')};
  background: #33347d;
  color: #ffffff;
  cursor: pointer;
`

const ChoiceLabel = styled.div<{ position: 'top' | 'bottom' }>`
  width: 100%;
  font-weight: 600;
  /* backdrop-filter: blur(3px); */
  font-size: 16px;
  box-sizing: border-box;
  text-align: center;
  padding: 10px 0px;

  position: absolute;
  ${({ position }) => (position === 'top' ? `top: 0;` : `bottom: 0;`)}
  ${({ position }) =>
    position === 'top'
      ? `background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0))`
      : `background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9))`}
`

const ChoiceImage = styled.img`
  height: 100%;
  width: 100%;
`
